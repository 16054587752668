import React from "react";
import Button from 'react-bootstrap/Button'
import DoctorImg from "../Assets/doctors-home.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import About from "./About";
import Mission from "./Mission";
import Work from "./Work";
import MissionNew from "./MissionNew";

const Home = () => {
  return (
    <>
          <Navbar />

    <div className="App">
    
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-text-section">
          <h3 className="home-txt-green">
            WELCOME TO OOSPHEEREART GAMETE SERVICES PVT LTD
          </h3>
          <h1 className="primary-heading">We are your IVF Business Experts.</h1>
          <p className="primary-text">
            With a collective experience of over 25 years, our Expert TEAM
            bring together a diverse background in IVF Lab Projects, IVF
            Operations, Gynecology, Clinical ART, Embryology & Medico Legal
            practice. As the sole provider of end to end solutions in India, we
            empower gynecologists, IVF centers & Institutions to excel in the
            ever evolving field of assisted reproductive technologies.
          </p>
          <Button className="secondary-button" href="https://drive.google.com/uc?export=download&id=1vYS_t2-d3_UHGzjKEalXdsIdqTXV3d47">Download Brochure</Button>
        </div>
        <div className="home-image-section">
          <img src={DoctorImg} alt="" />
        </div>
      </div>
      <Work />
      <Mission />
      

      <About />
    </div>
    </div>
    <Footer />
    </>
          

  );
};

export default Home;
