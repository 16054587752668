import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      id: 1,
      text: "Home",
      icon: <HomeIcon />,
      link: "https://oospheereart.com/",
    },
    {
      id: 2,
      text: "Connect With Us",
      icon: <PhoneRoundedIcon />,
      link: "https://forms.gle/TNZxQQovv8inB19y7",
      external: true,
    },{
      id: 3,
      text: "Our Team",
      link: "/team",
      external: false,
    },
  ];
  return (
    <div className="App-nav">

    
    <nav style={{backgroundColor: "#f6fbfe" }}>
      <div className="footer-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        <a className="navbar-items" href="https://oospheereart.com/">
          Home
        </a>
        <a
          className="navbar-items"
          href="https://forms.gle/TNZxQQovv8inB19y7"
          target="_blank"
          rel="noreferrer"
        >
          Connect with us
        </a>
        <Link to={"/team"}>Our Team</Link>

      </div>


      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Link
                    to={item.link}
                    target={item.external ? "_blank" : "_self"}
                    rel={item.external ? "noreferrer" : ""}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemText primary={item.text} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
    </div>
  );
};

export default Navbar;
