import React from "react";
import Logo from "../Assets/logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
      
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-section-columns">
          <span>+91-9943383966</span>
          <span>oospheereart@gmail.com</span>
        </div>
        <div className="footer-icons">
          <a href="https://www.linkedin.com/company/oospheere-art-gamete-services-pvt-ltd/" target="_blank" rel="noreferrer"><SiLinkedin /></a>
          <BsTwitter />
          <BsYoutube />
          <FaFacebookF />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span><a className="footer-items" href="https://oospheereart.com/">Home</a></span>
          <span><a className="footer-items" href="https://drive.google.com/uc?export=download&id=1vYS_t2-d3_UHGzjKEalXdsIdqTXV3d47">Know More About Us</a></span>
          <span><a className="footer-items" href="https://forms.gle/TNZxQQovv8inB19y7" target="_blank" rel="noreferrer">Connect with Us</a></span>
        </div>
      </div>
      
    </div>
  
  );
};

export default Footer;
