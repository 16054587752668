import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DrUmre from "../Assets/Dr-Amrita-Umre.jpeg";
import DrGajbhiye from "../Assets/Dr-Asha-Gajbhiye.jpeg";
import DrRohilla from "../Assets/Dr-Ashish-Rohilla.jpeg";
import DrWalke from "../Assets/Dr-Dilip-Walke.jpeg";
import DrHamine from "../Assets/Dr-Madhur-Hamine.jpeg";
import DrKarkhanis from "../Assets/Dr-Mayura-Karkhanis.jpeg";
import DrKorke from "../Assets/Dr-Subodh-Korke.jpeg";
import AdvKarkhanis from "../Assets/Adv-Amit-Karkhanis.jpeg";
import MrMane from "../Assets/Dhananjay-Mane.jpeg";
import BlankProfile from "../Assets/blank-profile.png";
import Navbar from "./Navbar";
import Footer from "./Footer";

const teamMembers = [
  {
    id: 1,
    name: "Dr. Dilip B. Walke",
    photo: DrWalke,
    position: "Director - Oospheere",
    role: "MD Obstetrician & Gynaecologist",
    experience: "35 years of experience as Gynecologist and Medicolegal Consultant",
    location: "PUNE",
  },
  {
    id: 2,
    name: "Dr. Madhur Hamine",
    photo: DrHamine,
    position: "Director - Oospheere",
    role: "IVF Business Expert",
    experience: "Having 25yrs+ Industry Experience",
    location: "PUNE",
  },
  {
    id: 3,
    name: "Mayura Karkhanis",
    photo: DrKarkhanis,
    position: "Director - Oospheere",
    role: "Financial Consultant",
    experience: "12 + years experience in financial management",
    location: "MUMBAI",
  },
  {
    id: 4,
    name: "Subodh",
    photo: BlankProfile,
    position: "Director - Oospheere",
    role: "MBA Marketing",
    experience: "14+ Years Experience in IVF and Healthcare segment ",
    location: "PUNE",
  },
  {
    id: 5,
    name: "Adv. Amit Karkhanis",
    photo: AdvKarkhanis,
    position: "Advisor - Oospheere",
    role: "Sr Lawyer",
    experience: "Having 20yrs+ Experience in ART & Medicolegal Field",
    location: "MUMBAI",
  },
  {
    id: 6,
    name: "Amrita Umre",
    photo: DrUmre,
    position: "Sr Consultant - IVF Business",
    role: "Healthcare Operations, IT & Project Management",
    experience: "Having 15yrs+ Experience in Healthcare",
    location: "BANGALORE",
  },
  {
    id: 7,
    name: "Asha Gajbhiye",
    photo: DrGajbhiye,
    position: "Sr. Consultant -Vidarbha Region",
    role: "Healthcare Professional (Operations & Quality)",
    experience: "17+ Years of Experience",
    location: "NAGPUR",
  },
  {
    id: 8,
    name: "Ashish Rohilla",
    photo: DrRohilla,
    position: "Sr Consultant - IVF Business",
    role: "Global Consultant (Fertility & Genomics)",
    experience: "16+ Years of Experience",
    location: "DELHI",
  },
  {
    id: 9,
    name: "Vijay Singh Thakur",
    photo: BlankProfile,
    position: "Sr Consultant",
    role: "GDM, MBA Marketing ",
    experience: "22+ Years of Experience in Healthcare ",
    location: "BHOPAL",
  },
];

const cardStyle = {
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
};

const Team = () => {
  return (
    <>
    <Navbar />
    <div className="App">
      
      <div className="team-wrapper">
        <h3 className="primary-heading text-center fs-1">Meet Our Team</h3>
        <p className="team-text">
          At Oospheere, we bring together a team of highly skilled
          professionals, including Business experts, Financial Experts,
          Medicolegal experts, Gynaecologists, experienced embryologists,
          fertility specialists, and support staff. 
        </p>
        <Container>
          <Row className="justify-content-center">
            {teamMembers.map((member) => (
              <Col
                key={member.id}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                className="m-4"
              >
                <Card
                  style={{ width: "18rem", ...cardStyle }}
                  className="text-center Team-Cards py-3 d-flex flex-column h-100"
                >
                  <Card.Img
                    variant="top"
                    src={member.photo}
                    alt={member.name}
                    className="rounded-circle"
                    style={{
                      width: "130px",
                      height: "130px",
                      objectFit: "cover",
                      margin: "auto",
                    }}
                  />
                  <Card.Body className="d-flex flex-column flex-grow-1">
                    <Card.Title className="text-blue text-bold">
                      {member.name}
                      <div className="underline"></div>
                    </Card.Title>
                    <Card.Text className="flex-grow-1">
                      <div className="text-grey text-bold pb-2">{member.position}</div>
                      <div>{member.role}</div>
                      <div className="text-grey fs-6">{member.experience}</div>
                      <div className="text-green mt-3">{member.location}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Team;
