import React from "react";
import Doctors from "../Assets/doctors-home2.png";
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";


const About = () => {
  return (
    <>
      <h3 className="primary-heading mt-10 fs-6">ABOUT OOSPHEERE </h3>

      <div className="about-section-container">
        

        <div className="about-section-text-container">
          <p className="about-text">
            At Oospheere ART Gamete Services Pvt Ltd, we are at the forefront of
            pioneering fertility solutions through our expert In Vitro
            Fertilization (IVF) Business Consulting services. As a trusted name
            in the IVF business services industry, we are committed to helping
            individuals and couples realize their dream of parenthood.
          </p>
          <Link to="/team">
            {" "}
            <Button
              className="secondary-button mt-3"
            >
              Read More
            </Button>
          </Link>
        </div>

        <div className="about-section-image-container">
          <img src={Doctors} alt="" />
        </div>
      </div>
    </>
  );
};

export default About;
