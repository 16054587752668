import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Mission = () => {
  return (
    <div className="mission-container mt-6">
      <div className="mission-content p-4 m-3">
        <h3 className="primary-heading fs-6 text-start with-circle text-grey">OUR MISSION</h3>
        
        <p className="mission-text text-start fs-2">
        Empowering Gynaecologists & Healthcare Institutes through 360-degree <span className="text-blue">IVF Business solutions.</span>
        </p>
        
      </div>

      <div className="vision-content p-4 m-3">
        <h3 className="primary-heading fs-6 text-start with-circle text-grey">OUR VISION</h3>
        
        <p className="mission-text text-start fs-2">
        Pioneering excellence in <span className="text-blue">ART Business Solutions</span> and Unleashing IVF Excellence in Every Gynaecological Practice.
        </p>
        
      </div>
    </div>
  );
};

export default Mission;
