import React from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Service1 from "../Assets/service-icon-1.png";
import Service2 from "../Assets/service-icon-2.png";
import Service3 from "../Assets/service-icon-3.png";
import Service4 from "../Assets/service-icon-4.png";
import Service5 from "../Assets/service-icon-5.png";
import Service6 from "../Assets/service-icon-6.png";

const Work = () => {
  const workInfoData = [
    {
      image: Service1,
      title: "Turn Key IVF Project",
      text: "",
    },
    {
      image: Service2,
      title: "Feasibility Study",
      text: "",
    },
    {
      image: Service3,
      title: "Business Modelling",
      text: "",
    },
    {
      image: Service4,
      title: "Lab Designing",
      text: "",
    },
    {
      image: Service5,
      title: "Equipment Planning",
      text: "",
    },
    {
      image: Service6,
      title: "Equipment Supply",
      text: "",
    },
    {
      image: Service1,
      title: "Centre Operations & Business Development Advisory",
      text: "",
    },
    {
      image: Service2,
      title: "ART & Embryology Support",
      text: "",
    },
    {
      image: Service3,
      title: "ART Medico Legal Advisory",
      text: "",
    },
    {
      image: Service4,
      title: "ART Banking",
      text: "",
    },
  ];

  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400, // Tablets and iPads
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1070, // Tablets and iPads
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="App">
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <h1 className="primary-heading fs-6 text-grey">SERVICES</h1>
          <h1 className="primary-heading fs-1 text-green mt-2">What We Do</h1>

        </div>

        <div className="work-section-bottom Work-Card">
          <Slider {...settings}>
            {workInfoData.map((data) => (
              <Card key={data.title} style={{ margin: '0 10px' }}>
                <div className="work-section-info">
                  <div className="info-boxes-img-container">
                    <img src={data.image} alt="" />
                  </div>
                  <h2 className="service-title">{data.title}</h2>
                </div>
              </Card>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Work;
