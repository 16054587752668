import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Components/Home";
import Work from "./Components/Work";
import Team from "./Components/Team";

//import About from "./Components/About";
//import Footer from "./Components/Footer";


function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Work />} />
        <Route path="/team" element={<Team />} />
      </Routes>
    </Router>
  );
}

export default App;